import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Gymowner"}
      {new Date().getFullYear()}
      {" - Hecho por Eric Daniele. "}
    </Typography>
  );
}

const theme = createTheme();
const isEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function SignIn() {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsDisabled(true);
    const data = new FormData(event.currentTarget);



    const Config = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email: data.get('email'),
        password: data.get('password'),
      }
    }
    if(!isEmail(data.get('email'))){
      setIsDisabled(false);
      toast.error("Email invalido", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
      return;
    }
    
    if(data.get('email').length === 0 || data.get('password').length === 0){
      setIsDisabled(false);
      toast.error("Complete todos los campos", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
      return;
    }
    axios(`${process.env.REACT_APP_API_URL}/auth/login`, Config)
    .then(res => {
      localStorage.setItem('token', res.data.token);
      navigate(`/${res.data.user.tenant}/home`, { replace: true });
    }).catch(err => {
      setIsDisabled(false);
      if(err.code === 'ERR_NETWORK') {
        toast.error("Error de sistema", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        return;
      }
      if(err.code === 'ERR_BAD_REQUEST') {
        toast.error("Credenciales incorrectas", {
          poosition: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        })
      }
    })
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <ToastContainer />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h3">Gymowner</Typography>
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}></Avatar>
          <Typography component="h1" variant="h5">
            Ingreso
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              disabled={isDisabled}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              disabled={isDisabled}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                {/* <Link href={`/${params.tenant}/signup`} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
