import React, { useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import Shortbody from "../../components/Shortbody/Shortbody";
import { Container } from "@mui/material";
import {
  Typography,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export default function Acl() {
  const [permissionData, setPermissionData] = useState([{}]);
  const [userChecked, setUserChecked] = useState([]);
  const [guestChecked, setGuestChecked] = useState([]);
  const [ownerChecked, setOwnerChecked] = useState([]);

  const handleAccessSwitch = (e) => {
    const token = localStorage.getItem("token");
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      data: {
        functionName: e.target.name,
        role: e.target.value,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/acl/switchpermission`, Config).then(
      (res) => {
        const Config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        };
        axios(`${process.env.REACT_APP_API_URL}/acl/getpermissions`, Config).then(
          (res) => {
    
            setPermissionData(res.data.permissions);
            setUserChecked(res.data.userFunctions);
            setGuestChecked(res.data.guestFunctions);
            setOwnerChecked(res.data.ownerFunctions);
          }
        );
      }
    );
  };


  const columns = [
    { 
      name: "Name",
      selector: (row) => row.name,
    },
    {
    
      name: "Owner",
      selector: (row) => {
      return <Checkbox name={row.name} value={'owner'} onChange={handleAccessSwitch} checked={ownerChecked.includes(row.name)}/>
    },
    },
    {
    
      name: "User",
      selector: (row) => <Checkbox name={row.name} value={'user'} onChange={handleAccessSwitch} checked={userChecked.includes(row.name)}/>,
    },
    {
    
      name: "Guest",
      selector: (row) => <Checkbox name={row.name} value={'guest'} onChange={handleAccessSwitch} checked={guestChecked.includes(row.name)}/>,
    },
  ];
  useEffect(() => {
    const token = localStorage.getItem("token");
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/acl/getpermissions`, Config).then(
      (res) => {

        setPermissionData(res.data.permissions);
        setUserChecked(res.data.userFunctions);
        setGuestChecked(res.data.guestFunctions);
        setOwnerChecked(res.data.ownerFunctions);
      }
    );
  }, []);
  return (
    <>
      <Shortbody>
        <Container>
          {permissionData.map((item) => {
            const funcarray = [];
            item.functions &&
              item.functions.map((func) => funcarray.push({ name: func }));
            return (

                <Accordion key={`accordion-${item.controller}`}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography variant="h4" component="h2">
                      {item.controller && item.controller.charAt(0).toUpperCase() + item.controller.slice(1)}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <DataTable columns={columns} data={funcarray} />
                  </AccordionDetails>
                </Accordion>

            );
          })}
        </Container>
      </Shortbody>
    </>
  );
}
