import React from "react";
import DataTable from "react-data-table-component";
import {
  Paper,
  Box,
  MenuItem,
  Select,
  Grid,
  Typography,
  FormControl,
  Input,
  InputLabel,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
const columns = [
  {
    name: "Cliente",
    selector: (row) => row["name"],
  },
  {
    name: "Fecha",
    selector: (row) => {
      const dateformat = new Date(row["date"]).toLocaleString();
      if (dateformat === "Invalid Date") return row["date"];
      return dateformat;
    },
  },
  {
    name: "Accion",
    selector: (row) => {
      switch (row["action"]) {
        case "IN":
          return "Entrada";
        case "OUT":
          return "Salida";
        case "OUTCRON":
          return "Salida forzada";
        default:
          break;
      }
    },
  },
];

const refetchLastVisits = (limit, page, startDate, endDate, setPageAmount, setTableData) => {
  const Config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      limit,
      startDate,
      endDate,
      page,
    },
  };
  axios(
    `${process.env.REACT_APP_API_URL}/clientvisits/getvisits`,
    Config
  ).then((res) => {
    setPageAmount(res.data.pages - 1);
    setTableData(res.data.responseVisits);
  });
};

export default function AllVisitsDataTable() {
  const [tabledata, setTableData] = useState([]);
  const [pageAmount, setPageAmount] = useState(10);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

 

  useEffect(() => {
    refetchLastVisits(limit, page, startDate, endDate, setPageAmount, setTableData);
  }, [page, limit, startDate, endDate]);

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < pageAmount) {
      setPage(page + 1);
    }
  };
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <h1 style={{ textAlign: "center" }}>Ultimas visitas</h1>
      </Grid>
      <Grid
        item
        container
        sx={{ justifyContent: "space-around", alignContent: "center" }}
      >
        <Grid item>
          <InputLabel id="ilb">Desde</InputLabel>
          <Input
            labelId="ilb"
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
          ></Input>
        </Grid>
        <Grid item>
          <InputLabel id="ilb2">Hasta</InputLabel>
          <Input
            labelId="ilb2"
            label="Hasta"
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
          ></Input>
        </Grid>
        <Grid item  sx={{display:'flex', justifyContent: 'center', alignItems:'center'}}>
          <Button
            variant="contained"
            onClick={() => {
              refetchLastVisits();
            }}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Paper>
          <DataTable selectableRows columns={columns} data={tabledata} />
        </Paper>
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            alignItems: "center",
            pt: 5,
          }}
        >
          <Button variant="outlined" onClick={prevPage}>
            {"<"}
          </Button>
          <Typography sx={{ ml: 3, mr: 3 }}>
            {page + " / " + pageAmount}
          </Typography>
          <Button variant="outlined" onClick={nextPage}>
            {">"}
          </Button>
          <FormControl
            sx={{ position: "absolute", right: 0, minWidth: "100px" }}
          >
            <InputLabel id="input-client-sex-filter-label" size="small">
              Cant.
            </InputLabel>
            <Select
              defaultValue={limit}
              onChange={(event) => setLimit(event.target.value)}
              size="small"
              id="input-client-sex-filter"
              labelId="input-client-sex-filter-label"
              label="Sexo"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={99999}>Todo</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}
