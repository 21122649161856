import { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  FormControl,
  LinearProgress,
  Grid,
  Modal,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function MainComponent(props) {
  const [dni, setDni] = useState("");
  const [clientData, setClientData] = useState();
  const [progressValue, setProgressValue] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const token = localStorage.getItem("token");
  const {tenant} = jwtDecode(token);
  
  function handleSubmit(event) {
    event.preventDefault();
    setProgressValue(0);
    const data = new FormData(event.currentTarget);
    setDni("");
    if (data.get("input-client-dni").length !== 8) {
      toast.error("DNI INVALIDO", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        dni: data.get("input-client-dni"),
      },
    };

    async function LinearAddRecursive() {
      const timer = setInterval(() => {
        setProgressValue((prev) => {
          if (prev >= 100) {
            clearInterval(timer);
            setTimeout(() => {
              handleClose();
            }, 1000);
            return prev;
          }
          return prev + 1;
        });
      }, 50);
    }

    axios(`${process.env.REACT_APP_API_URL}/clientvisits/addvisit`, Config)
      .then((res) => {
        let actionLabel = "";
        setClientData(res.data.client);
        LinearAddRecursive();
        handleOpen(true);
        res.data.action === "IN"
          ? (actionLabel = "INGRESO CONFIRMADO")
          : (actionLabel = "SALIDA CONFIRMADA!");
        toast.success(`💪 ${actionLabel}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast.error("DNI INVALIDO", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("ERROR DE CONECCION", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        });
  }

  return (
    <>
      <Modal
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper>
          <Grid
            container
            gap={4}
            sx={{
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Grid item>
              <Typography id="modal-modal-title" variant="h1" component="h2">
                Bienvenido {clientData && clientData.name}
              </Typography>
            </Grid>

            {clientData && new Date(clientData && clientData.nextPayment) < new Date() ? (
              <Grid item>
                <Typography id="modal-modal-title" variant="h4" color='red' fontWeight='bold' component="h2">
                  CUOTA VENCIDA DESDE: {new Date(clientData && clientData.nextPayment).toLocaleDateString(
                      process.env.REACT_APP_LOCALE
                    )}
                </Typography>
              </Grid>
            ) : (
              <>
              <Grid item>
                  <Typography id="modal-modal-title" variant="h4" component="h2">
                    Fecha del proximo pago:{" "}
                    {new Date(clientData && clientData.nextPayment).toLocaleDateString(
                      process.env.REACT_APP_LOCALE
                      )}
                  </Typography>

              </Grid>
              {clientData && (clientData.debts.length > 0) ?<Grid item>
                <Typography id="modal-modal-title" variant="h4" component="h2">
                    Tienes deudas pendientes
                  </Typography>
                </Grid>:<></>}
              
              </>
            )}

            <Grid item>
              <LinearProgress variant="determinate" value={progressValue} />
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <Box
        style={{
          backgroundImage: `url(${tenant}-bg.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        <ToastContainer />
        <Paper
          component="form"
          onSubmit={handleSubmit}
          style={{
            marginTop: "100px",
            padding: "16px",
            maxWidth: "500px",
            width: "90%",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            Ingreso/Egreso
          </Typography>
          <img
            src={`${tenant}-logo.jpg`}
            alt="logo"
            style={{ width: "100px", marginBottom: "16px" }}
          />

          <Typography variant="h4" component="h4" gutterBottom>
            DNI
          </Typography>
          <FormControl>
            <TextField
              autoFocus
              type="number"
              maxLength={8}
              minLength={8}
              autoComplete="off"
              inputProps={{
                style: {
                  fontSize: 40,
                  textAlign: "center",
                  fontWeight: "bold",
                  maxLength: 12,
                },
              }}
              id="input-client-dni"
              value={dni}
              name="input-client-dni"
              onChange={(e) => setDni(e.target.value)}
              helperText="Ingresa tu DNI"
              fullWidth
              variant="outlined"
            />
          </FormControl>
        </Paper>
      </Box>
    </>
  );
}

export default MainComponent;
