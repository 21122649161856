import React from "react";
import { Grid } from "@mui/material";

import AllVisitsDataTable from "../../components/Visits/AllVisitsDataTable";
import ClientVisitsDataTable from "../../components/Visits/ClientVisitsDataTable";

export default function Visits() {
  return (
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} md={6}>
        <AllVisitsDataTable />
        </Grid>
        <Grid item xs={12} md={6}>
        <ClientVisitsDataTable />
        </Grid>
      </Grid>
  );
}
