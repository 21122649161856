import React from "react";
import Shortbody from "../../components/Shortbody/Shortbody";
import DataTable from "react-data-table-component";
import {
  Paper,
  Modal,
  Grid,
  Button,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import { useState, useEffect } from "react";
import axios from "axios";

function getCajasHistorial(setTableData) {
  const Config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  axios(`${process.env.REACT_APP_API_URL}/caja/getcajashistorial`, Config)
    .then((res) => {
      setTableData(res.data);
    })
    .catch((err) => {
      console.log(err.message);
    });
}

export default function CajaHistorial() {
  const [cajaInfo, setCajaInfo] = useState();
  const [tabledata, setTableData] = useState([]);
  const [openCajaInfo, setOpenCajaInfo] = useState(false);

  const handleOpenCajaInfo = () => setOpenCajaInfo(true);
  const handleCloseCajaInfo = () => setOpenCajaInfo(false);

  function handleView(id) {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        _id: id,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/caja/getcajainfototal`, Config)
      .then((res) => {
        setCajaInfo(res.data);
        handleOpenCajaInfo();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const columns = [
    {
      name: "Fecha Apertura",
      selector: (row) => {
        const dateformat = new Date(row["fechaApertura"]).toLocaleString(
          process.env.REACT_APP_LOCALE
        );
        if (dateformat === "Invalid Date") return row["fechaApertura"];
        return dateformat;
      },
      grow: 1,
    },
    {
      name: "Fecha Cierre",
      selector: (row) => {
        const dateformat = new Date(row["fechaCierre"]).toLocaleString(
          process.env.REACT_APP_LOCALE
        );
        if (dateformat === "Invalid Date") return row["fechaApertura"];
        return dateformat;
      },
      grow: 1,
    },
    {
      name: "Monto Apertura",
      grow: 1,
      selector: (row) =>
        parseFloat(row["montoApertura"]).toFixed(2).replace(".", ","),
    },
    {
      name: "Monto Cierre",
      grow: 1,
      selector: (row) =>
        parseFloat(row["montoCierre"]).toFixed(2).replace(".", ","),
    },
    {
      grow: 1,
      selector: (row) => {
        return (
          <PreviewIcon
            onClick={() => handleView(row._id)}
            sx={{ cursor: "pointer" }}
            fontSize="large"
          ></PreviewIcon>
        );
      },
    },
  ];

  const colMovimientos = [
    {
      name: "Fecha",
      selector: (row) => {
        const dateformat = new Date(row["fecha"]).toLocaleString(
          process.env.REACT_APP_LOCALE
        );
        if (dateformat === "Invalid Date") return row["fecha"];
        return dateformat;
      },
      grow: 2,
    },
    {
      name: "Concepto",
      selector: (row) => {
        return row["concepto"];
      },
      grow: 2,
    },
    {
      name: "Ingreso",
      selector: (row) => {
        return row["ingreso"];
      },
    },
    {
      name: "Egreso",
      selector: (row) => {
        return row["egreso"];
      },
    },
    {
      name: "Monto",
      selector: (row) => {
        return row["monto"];
      },
    },
  ];

  

  useEffect(() => {
    getCajasHistorial(setTableData);
  }, []);

  return (
    <>
      <Modal
        sx={{ overflowY: "scroll",padding:4 }}
        open={openCajaInfo}
        onClose={handleCloseCajaInfo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper>
          <Grid container spacing={2} padding={2} >
            <Grid
              item
              container
              xs={12}
              sx={{ alignItems: "end" }}
              justifyContent='flex-end'
            >
              <Button onClick={handleCloseCajaInfo} variant="contained">
                Cerrar
              </Button>
            </Grid>
            <Grid item container>
              <Grid item>
                <b>Monto de cierre: ${cajaInfo && cajaInfo.montoCierre}</b>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <DataTable
                  highlightOnHover
                  columns={colMovimientos}
                  data={cajaInfo && cajaInfo.movimientos}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      <Shortbody>
        <Paper>
          <DataTable highlightOnHover columns={columns} data={tabledata} />
        </Paper>
      </Shortbody>
    </>
  );
}
