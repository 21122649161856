import React from "react";
import DataTable from "react-data-table-component";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Modal,
  Grid,
  Typography,
  Pagination,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Box,
  Button,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CottageIcon from "@mui/icons-material/Cottage";
import WcIcon from "@mui/icons-material/Wc";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState, useEffect } from "react";
import CRUDControls from "../../components/CRUDControls/CRUDControls";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { Stack } from "@mui/system";
import PaymentsIcon from "@mui/icons-material/Payments";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const columnsTenPayments = [
  {
    name: "Fecha de pago",
    selector: (row) =>
      new Date(row["createdAt"]).toLocaleString(process.env.REACT_APP_LOCALE),
  },
  {
    name: "Desde",
    selector: (row) => 
    new Date(row['from']).toLocaleString(process.env.REACT_APP_LOCALE)
  },
  {
    name: "Hasta",
    selector: (row) => 
      new Date(row['to']).toLocaleString(process.env.REACT_APP_LOCALE)
  },
];

const columns = [
  {
    name: "Estado",
    selector: (row) => {
      if (new Date(row["nextPayment"]) < new Date()) {
        return <MonetizationOnIcon sx={{ color: "red" }} />;
      }

      if (!row["nextPayment"]) {
        return <MonetizationOnIcon sx={{ color: "grey" }} />;
      }
      return <MonetizationOnIcon sx={{ color: "green" }} />;
    },
    grow: 1,
  },
  {
    name: "Nombre",
    selector: (row) => row["name"],
    grow: 2,
  },
  {
    name: "DNI",
    selector: (row) => row["dni"],
  },
  {
    name: "Email",
    grow: 2,
    selector: (row) => row["email"],
  },
  {
    name: "Sexo",
    selector: (row) => row["sex"],
  },
  {
    name: "Telefono",
    selector: (row) => row["phone"],
  },
  {
    name: "Ultima Visita",
    selector: (row) => {
      const dateformat = new Date(row["lastVisit"]).toLocaleString(
        process.env.REACT_APP_LOCALE
      );
      if (dateformat === "Invalid Date") return row["lastVisit"];
      return dateformat;
    },
  },
  {
    name: "Fecha de Registro",
    grow: 2,
    selector: (row) => {
      const dateformat = new Date(row["createdAt"]).toLocaleString(
        process.env.REACT_APP_LOCALE
      );
      if (dateformat === "Invalid Date") return row["createdAt"];
      return dateformat;
    },
  },
];

const getClientsFiltered = (setPageAmount, setTableData, page, limit) => {
  const Config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      page,
      limit,
    },
  };
  axios(
    `${process.env.REACT_APP_API_URL}/clients/getclientsfiltered`,
    Config
  ).then((res) => {
    setPageAmount(res.data.pages - 1);
    setTableData(res.data.clients);
  });
};

export default function Clients() {
  const [tabledata, setTableData] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [pageAmount, setPageAmount] = useState(0);
  const [filterSwitch, setFilterSwitch] = useState("none");
  const [cargandoMembresia, setCargandoMembresia] = useState(false);
  const [dataMembresia, setDataMembresia] = useState([]);
  const [membershipData, setMembershipData] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [modalEditarUsuario, setModalEditarUsuario] = useState(false);
  const [dataUsuario, setDataUsuario] = useState({});

  const closeModalEditarUsuario = () => {
    setModalEditarUsuario(false);
  };

  const refetchMembershipInfo = () => {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        clientId: currentUser,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/getmembershipinfo`, Config)
      .then((res) => {
        setDataMembresia(res.data);
        setCargandoMembresia(false);
      })
      .catch((err) => {});
  };
  const openModalEditarUsuario = (data) => {
    setDataUsuario(data);
    setModalEditarUsuario(true);
  };



  const handleDarDeBaja = (data) => {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        clientId: data._id,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/deleteclient`, Config).then(
      (res) => {
        window.location.reload();
      }
    );
  };
  const [openConfirmDarDeBaja, setOpenConfirmDarDebaja] = useState(false);
  const [dataBaja, setDataBaja] = useState();
  const handleOpenConfirmDarDeBaja = (data) => {
    setDataBaja(data);
    setOpenConfirmDarDebaja(true);
  };
  const handleCloseConfirmDarDeBaja = () => setOpenConfirmDarDebaja(false);

  const ModalConfirmDarDeBaja = () => {
    return (
      <Modal
        open={openConfirmDarDeBaja}
        onClose={handleCloseConfirmMembresia}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
      >
        <Paper>
          <Grid
            item
            container
            gap={2}
            sx={{
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            Estas seguro de que desea dar de baja este usuario?
            <Grid
              item
              container
              gap={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => {
                  handleDarDeBaja(dataBaja);
                  handleCloseConfirmDarDeBaja();
                }}
                variant="contained"
                color="success"
              >
                Aceptar
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseConfirmDarDeBaja}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    );
  };

  const handleCancelMembership = (id) => {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        membershipId: id,
        clientId: currentUser,
      },
    };
    axios(
      `${process.env.REACT_APP_API_URL}/clients/deletemembershipfromclient`,
      Config
    ).then((res) => {
      setDataMembresia({
        ...dataMembresia,
        clientMembership: null,
      });
      toast.success("Membresia quitada con exito");
      refetchMembershipInfo();
    });
  };

  const handleSubmitEditarUsuario = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get("client-dni").length > 0) {
      if (!/^\d+$/.test(data.get("client-dni"))) {
        return toast.error("DNI no puede contener letras");
      }
      if (data.get("client-dni").length !== 8) {
        return toast.error("DNI debe tener 8 caracteres");
      }
    }

    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        _id: data.get("client-id"),
        fields: {
          name: data.get("client-name"),
          dni: data.get("client-dni"),
          email: data.get("client-email"),
          phone: data.get("client-phone"),
          sex: data.get("client-sex"),
        },
        page,
        limit,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/updateclient`, Config)
      .then((res) => {
        closeModalEditarUsuario();
        toast.success("Usuario editado con exito");
      })
      .catch((err) => {
        toast.error("Error");
      })
      .finally(() => {
        getClientsFiltered(setPageAmount, setTableData, page, limit);
      });
  };

  const [modalGestionarOpen, setModalGestionarOpen] = useState(false);

  const handleAsignarMembresia = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        clientId: currentUser,
        membershipId: data.get("select-membership"),
      },
    };
    axios(
      `${process.env.REACT_APP_API_URL}/clients/addmembershiptoclient`,
      Config
    )
      .then((res) => {
        toast.success("Membresia asignada con exito");
        const Config = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            clientId: currentUser,
          },
        };
        axios(
          `${process.env.REACT_APP_API_URL}/clients/getmembershipinfo`,
          Config
        )
          .then((res) => {
            setDataMembresia(res.data);
            setCargandoMembresia(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  const closeModalGestionar = () => {
    setModalGestionarOpen(false);
  };
  function fetchMembershipInfo(data, pageP) {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        clientId: data._id,
        page: pageP - 1
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/getmembershipinfo`, Config)
      .then((res) => {
        console.log(res.data);
        setDataMembresia(res.data);
      })
      .catch((err) => {});
  }


  function openModalGestionar(data) {
    setModalGestionarOpen(true);
    setCargandoMembresia(true);
    setCurrentUser(data._id);
    fetchMembershipInfo({_id: data._id}, 1);
    const Config2 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/getmembershiplist`, Config2)
      .then((res) => {
        setMembershipData(res.data);
        setCargandoMembresia(false);
      })
      .catch((err) => {
        setCargandoMembresia(false);
      });
  };

  const handleAbonoPagination = (evento, pageP) => {

    fetchMembershipInfo(dataMembresia, pageP)

  }

  const LastTenPayments = (data) => {
    return (
      <>
        <DataTable
          noDataComponent="No hay registros"
          columns={columnsTenPayments}
          data={data.data}
          />
      </>
    );
  };

  const [AbonarEspecificoModal, setAbonarEspecificoModal] = useState(false);

  const openAbonarEspecifico = () => setAbonarEspecificoModal(true);
  const closeAbonarEspecifico = () => setAbonarEspecificoModal(false);

  const handleSubmitAbonarEspecifico = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if(dataMembresia && data.get('client-debt') && (data.get('client-debt') === dataMembresia.clientMembership.membership.cost)) {
      return toast.error("No se puede deber la totalidad del costo de la membresia");
    }

    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        membershipId: dataMembresia.clientMembership._id,
        specificDay: data.get('client-pdate'),
        debt: data.get('client-debt')?data.get('client-debt'):null
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/paymembership`, Config)
      .then((res) => {
        getClientsFiltered(setPageAmount, setTableData, page, limit);
        refetchMembershipInfo();
        toast.success("Membresia abonada con exito");
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });

    closeAbonarEspecifico();
  };

  //FORM MODAL ABONAR ESPECIFICO
  const ModalAbonarEspecifico = () => {
    return (
      <Modal
        open={AbonarEspecificoModal}
        onClose={closeAbonarEspecifico}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
        onSubmit={handleSubmitAbonarEspecifico}
      >
        <Paper>
          <Grid
            item
            container
            gap={2}
            sx={{
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            Selecciona el dia en el que comenzará a correr la siguiente cuota
            <Input
            sx={{height:'30px'}}
            type="date"
            required
            name="client-pdate"
            id="input-client-pdate"
            size="lg"
          />
          <Paper sx={{display:'flex', padding:5, flexDirection:'column', backgroundColor:'#EEEEEE'}}>
            <b>Solo en caso de abonar parte de la cuota.</b> Escriba el monto parcial a abonar:
            <Input
              sx={{height:'30px'}}
              type="number"
              name="client-debt"
              variant="outlined"
              id="input-client-debt"
              size="lg"
            />
          </Paper>
            <Grid
              item
              container
              gap={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                color="success"
                type="submit"
                endIcon={<SendIcon />}
              >
                Aceptar
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={closeAbonarEspecifico}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    );
  };

  //MODAL DE CONFIRMAR ABONO DE MEMBRESIA
  const [openConfirmMembresia, setOpenConfirmMembresia] = useState(false);

  const handleCloseConfirmMembresia = () => setOpenConfirmMembresia(false);
  // const handleOpenConfirmMembresia = () => setOpenConfirmMembresia(true);

  const ModalConfirmMembresia = (membershipId) => {
    return (
      <Modal
        open={openConfirmMembresia}
        onClose={handleCloseConfirmMembresia}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
      >
        <Paper>
          <Grid
            item
            container
            gap={2}
            sx={{
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            Esta seguro de que desea abonar la membresia?
            <br/>
            <b>
              {}
            {
            dataMembresia.nextPayment !== undefined
            ?"La fecha del proximo pago sera el dia: " 
            + new Date(
              new Date(dataMembresia.nextPayment)
              .setMonth(new Date(dataMembresia.nextPayment).getMonth() + 1)).toLocaleString(
                process.env.REACT_APP_LOCALE,
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )
              :"La fecha del proximo pago sera el dia: " + new Date().toLocaleString(
                process.env.REACT_APP_LOCALE,
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </b>
            <Grid
              item
              container
              gap={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => {
                  handleAbonarMembresia(dataMembresia.clientMembership._id);
                  handleCloseConfirmMembresia();
                }}
                variant="contained"
                color="success"
              >
                Aceptar
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseConfirmMembresia}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    );
  };

  //MODAL DE CONFIRMAR BAJA DE MEMBRESIA DE USUARIO

  const [openConfirmCancelarMembresia, setOpenConfirmCancelarMembresia] =
    useState(false);

  const handleCloseConfirmCancelarMembresia = () =>
    setOpenConfirmCancelarMembresia(false);
  const handleOpenConfirmCancelarMembresia = () =>
    setOpenConfirmCancelarMembresia(true);

  const ModalConfirmCancelarMembresia = (membershipId) => {
    return (
      <Modal
        open={openConfirmCancelarMembresia}
        onClose={handleCloseConfirmCancelarMembresia}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
      >
        <Paper>
          <Grid
            item
            container
            gap={2}
            sx={{
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            Esta seguro de que desea quitarle la membresia al usuario?
            <Grid
              item
              container
              gap={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => {
                  handleCancelMembership(dataMembresia.clientMembership._id);
                  handleCloseConfirmCancelarMembresia();
                }}
                variant="contained"
                color="success"
              >
                Aceptar
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseConfirmCancelarMembresia}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    );
  };

  const handleAbonarMembresia = (membershipId) => {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        membershipId: membershipId,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/paymembership`, Config)
      .then((res) => {
        getClientsFiltered(setPageAmount, setTableData, page, limit);
        refetchMembershipInfo();
        toast.success("Membresia abonada con exito");
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  const MembresiaActivas = () => {
    return (
      <Grid item container spacing={2} xs={12}>
        <Grid
          item
          container
          gap={2}
          sx={{ display: "flex", flexDirection: "row", textAlign: "center" }}
        >
          <Grid xs={12} item>
            <Typography variant="h3">
              {dataMembresia.clientMembership.membership.name}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography variant="h6">
              Costo de membresia: $
              {dataMembresia.clientMembership.membership.cost}
            </Typography>
          </Grid>
          <Grid xs={12} item container justifyContent="space-around">
            {/* <Grid item>
              <Button
                onClick={handleOpenConfirmMembresia}
                variant="contained"
                color="success"
              >
                Abonar proxima membresia
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                onClick={openAbonarEspecifico}
                variant="contained"
                color="warning"
              >
                Abonar en dia especifico
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() =>
                  handleOpenConfirmCancelarMembresia(
                    dataMembresia.clientMembership._id
                  )
                }
                color="error"
              >
                Cancelar membresia
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            Ultimos pagos
            <LastTenPayments data={dataMembresia.lastTenPayments} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const AsignarMembresia = () => {
    return (
      <Grid
        item
        container
        component="form"
        onSubmit={handleAsignarMembresia}
        spacing={2}
        sx={{ padding: 2 }}
      >
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6">Membresias:</Typography>
              <Select name="select-membership" id="membership">
                {membershipData &&
                  membershipData.map((obj) => {
                    return <MenuItem key={obj._id} value={obj._id}>{obj.name}</MenuItem>;
                  })}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              sx={{ marginTop: 2 }}
            >
              Asignar membresia
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleFilterSwitch = (event) => {
    setFilterSwitch(event.target.checked ? "block" : "none");
  };

  const ExpandUser = ({ data }) => {
    return (
      <Grid container direction="row" padding="2rem" spacing={4}>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => openModalEditarUsuario(data)}
          >
            <ManageAccountsIcon />
            Editar Datos
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => openModalGestionar(data)}>
            <PaymentsIcon />
            Gestionar membresias
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => handleOpenConfirmDarDeBaja(data)}
          >
            <DeleteForeverIcon />
            Dar de baja
          </Button>
        </Grid>
      </Grid>
    );
  };
  const ExpandedComponent = ({ data }) => <ExpandUser data={data}></ExpandUser>;

  const handlePaginationChange = (evento, pageP) => {
    setPage(pageP - 1);
  }

  const handleSubmitFilters = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        filters: {
          name: data.get("input-client-name-filter"),
          dni: data.get("input-client-dni-filter"),
          email: data.get("input-client-email-filter"),
          sex: data.get("input-client-sex-filter"),
          phone: data.get("input-client-phone-filter"),
        },
        estadoMembresia: data.get("input-client-membership-filter"),
        page,
        limit,
      },
    };

    axios(
      `${process.env.REACT_APP_API_URL}/clients/getclientsfiltered`,
      Config
    ).then((res) => {
      setPageAmount(res.data.pages - 1);
      setTableData(res.data.clients);
      setCargandoMembresia(false);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    if (!/^\d+$/.test(data.get("client-dni"))) {
      return toast.error("DNI no puede contener letras");
    }
    if (data.get("client-dni").length !== 8) {
      return toast.error("DNI debe tener 8 caracteres");
    }
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        name: data.get("client-name"),
        dni: data.get("client-dni"),
        email: data.get("client-email"),
        sex: data.get("client-sex"),
        phone: data.get("client-phone"),
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/makeclient`, Config)
      .then((res) => {
        setTableData([res.data, ...tabledata]);
        handleCloseCreate();
        toast.success("Cliente agregado correctamente");
      })
      .catch((err) => {
        if (err.response.data.code === 11000) {
          let duplicatedKey = Object.keys(err.response.data.keyPattern)[0];
          switch (duplicatedKey) {
            case "dni":
              duplicatedKey = "DNI";
              break;
            case "phone":
              duplicatedKey = "Telefono";
              break;
            default:
              break;
          }
          toast.error(
            `Ya existe un usuario con el mismo valor del campo ${duplicatedKey}`
          );
        } else {
          toast.error(err.response.data)
        }
      });
  };

  useEffect(() => {

    getClientsFiltered(setPageAmount, setTableData, page, limit);
  }, [page, limit]);


  return (
    <Box>
      <ToastContainer />
      {/*MODALS DE CONFIRMACION */}
      <ModalConfirmDarDeBaja />
      <ModalConfirmMembresia />
      <ModalConfirmCancelarMembresia />
      <ModalAbonarEspecifico />
      {/*ADD CLIENT MODAL*/}
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Paper variant="outlined" sx={{ py: 4, px: 4 }}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography marginX="auto" variant="h5">
                Agregar cliente
              </Typography>
            </Grid>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="input-client-name" required>
                  Nombre
                </InputLabel>
                <Input
                  required
                  name="client-name"
                  id="input-client-name"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountBoxIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="input-client-dni" required>
                  DNI
                </InputLabel>
                <Input
                  required
                  id="input-client-dni"
                  name="client-dni"
                  startAdornment={
                    <InputAdornment position="start">
                      <ChromeReaderModeIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="input-client-email">Email</InputLabel>
                <Input
                  id="input-client-email"
                  name="client-email"
                  startAdornment={
                    <InputAdornment position="start">
                      <AlternateEmailIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="input-client-address">
                  Direccion
                </InputLabel>
                <Input
                  id="input-client-adress"
                  name="client-adress"
                  startAdornment={
                    <InputAdornment position="start">
                      <CottageIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="input-client-sex">Sex</InputLabel>
                <Select
                  id="input-client-sex"
                  name="client-sex"
                  startAdornment={
                    <InputAdornment position="start" label="Not Specified">
                      <WcIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="Hombre">Hombre</MenuItem>
                  <MenuItem value="Mujer">Mujer</MenuItem>
                  <MenuItem value="">Sin especificar</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="input-client-phone">Telefono</InputLabel>
                <Input
                  id="input-client-phone"
                  name="client-phone"
                  startAdornment={
                    <InputAdornment position="start">
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  onClick={handleCloseCreate}
                  startIcon={<DeleteIcon />}
                >
                  CANCELAR
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<SendIcon />}
                >
                  ACEPTAR
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/*MODAL GESTIONAR MEMBRESIA*/}
      <Modal
        open={modalGestionarOpen}
        onClose={closeModalGestionar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        // onSubmit={handleSubmit}
      >
        <Paper variant="outlined" sx={{ py: 4, px: 4 }}>
          {cargandoMembresia ? (
            <CircularProgress />
          ) : (
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography marginX="auto" variant="h5">
                  {dataMembresia && dataMembresia.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  {"  "}
                  {dataMembresia &&
                    (new Date(dataMembresia.nextPayment).getTime() > new Date().getTime()
                      ? "Fecha del proximo pago: " +new Date(dataMembresia.nextPayment).toLocaleString(
                          process.env.REACT_APP_LOCALE,
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      : dataMembresia.nextPayment
                      ? "Vencido el "+new Date(dataMembresia.nextPayment).toLocaleString(process.env.REACT_APP_LOCALE,
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : "A DEFINIR")}
                </Typography>
              </Grid>
              {dataMembresia && dataMembresia.clientMembership ? (
                <>
                <MembresiaActivas />
                <Box sx={{display:"flex", justifyContent:'center'}}>
                    <Pagination count={dataMembresia.pages} size="large" variant="outlined" shape="rounded"  onChange={handleAbonoPagination} />
                  </Box>
                </>
                
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Este usuario no tiene membresias
                    </Typography>
                  </Grid>
                  <AsignarMembresia />
                  
                </>
              )}
              
            </Grid>
          )}
        </Paper>
      </Modal>

      {/*MODAL EDITAR USUARIO*/}
      <Modal
        open={modalEditarUsuario}
        onClose={closeModalEditarUsuario}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
        onSubmit={handleSubmitEditarUsuario}
      >
        <Paper variant="outlined" sx={{ py: 4, px: 4, mx: "auto", width: 600 }}>
          <Grid container spacing={4}>
            {/*DATOS VIEJOS*/}
            <input
              type="text"
              readOnly
              name="client-id"
              style={{ display: "none" }}
              value={dataUsuario && dataUsuario._id}
            />
            <Grid item container xs={6} spacing={2}>
              <Grid item xs={12}>
                <Typography marginX="auto" variant="h5">
                  Datos Actuales
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel>Nombre</InputLabel>
                  <Input
                    disabled
                    value={dataUsuario && dataUsuario.name}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountBoxIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel>DNI</InputLabel>
                  <Input
                    disabled
                    value={dataUsuario && dataUsuario.dni}
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel>Email</InputLabel>
                  <Input
                    disabled
                    value={dataUsuario && dataUsuario.email}
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel>Telefono</InputLabel>
                  <Input
                    disabled
                    value={dataUsuario && dataUsuario.phone}
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel>Sexo</InputLabel>
                  <Input
                    disabled
                    value={dataUsuario && dataUsuario.sex}
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/*DATOS NUEVOS */}
            <Grid item container xs={6} spacing={2}>
              <Grid item xs={12}>
                <Typography marginX="auto" variant="h5">
                  Datos Nuevos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="input-client-name">Nombre</InputLabel>
                  <Input
                    name="client-name"
                    id="input-client-name"
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountBoxIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="input-client-dni">DNI</InputLabel>
                  <Input
                    id="input-client-dni"
                    name="client-dni"
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="input-client-dni">Email</InputLabel>
                  <Input
                    id="input-client-email"
                    name="client-email"
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="input-client-dni">Telefono</InputLabel>
                  <Input
                    id="input-client-phone"
                    name="client-phone"
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="input-client-sex">Sex</InputLabel>
                  <Select
                    id="input-client-sex"
                    name="client-sex"
                    startAdornment={
                      <InputAdornment position="start" label="Not Specified">
                        <WcIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="Hombre">Hombre</MenuItem>
                    <MenuItem value="Mujer">Mujer</MenuItem>
                    <MenuItem value="">Sin especificar</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SendIcon />}
                >
                  Aceptar
                </Button>
                <Button
                  variant="outlined"
                  onClick={closeModalEditarUsuario}
                  startIcon={<DeleteIcon />}
                >
                  Cancelar
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      <Paper
        component="form"
        onSubmit={handleSubmitFilters}
        variant="outlined"
        sx={{ py: 4 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" pl={3}>
              Filtros <Switch onChange={handleFilterSwitch} />
            </Typography>
          </Grid>
          <Grid item sx={{ display: filterSwitch }} marginX="auto">
            <FormControl xs={12} md={1}>
              <TextField
                label="Nombre"
                name="input-client-name-filter"
                id="input-client-name-filter"
              />
            </FormControl>
          </Grid>
          <Grid item sx={{ display: filterSwitch }} marginX="auto">
            <FormControl xs={12} md={1}>
              <TextField
                label="DNI"
                name="input-client-dni-filter"
                id="input-client-dni-filter"
              />
            </FormControl>
          </Grid>
          <Grid item sx={{ display: filterSwitch }} marginX="auto">
            <FormControl xs={12} md={1}>
              <TextField
                label="Email"
                name="input-client-email-filter"
                id="input-client-email-filter"
              />
            </FormControl>
          </Grid>
          <Grid item sx={{ display: filterSwitch, minWidth:'220px'}} marginX="auto">
            <FormControl fullWidth>
              <InputLabel id="input-client-sex-filter-label">Sexo</InputLabel>
              <Select
                id="input-client-sex-filter"
                name="input-client-sex-filter"
                labelId="input-client-sex-filter-label"
                label="Sexo"
              >
                <MenuItem value={"Hombre"}>Hombre</MenuItem>
                <MenuItem value={"Mujer"}>Mujer</MenuItem>
                <MenuItem value={"No especificado"}>No especificado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ display: filterSwitch, minWidth:'220px' }} xs={2} marginX="auto">
            <FormControl sx={{ width:'100%' }}>
              <InputLabel id="input-client-membership-filter-label">Estado de membresia</InputLabel>
              <Select
                id="input-client-membership-filter"
                name="input-client-membership-filter"
                labelId="input-client-membership-filter-label"
                label="Membresia"
                defaultValue={''}
              >
                <MenuItem value={''}>Todos</MenuItem>
                <MenuItem value={"Vigente"}>Vigente</MenuItem>
                <MenuItem value={"Vencido"}>Vencido</MenuItem>
                <MenuItem value={"Sin membresia"}>Sin Membresia</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ display: filterSwitch }} marginX="auto">
            <FormControl>
              <TextField label="Telefono" name="input-client-telephone-filter" id="input-client-telephone-filter" />
            </FormControl>
          </Grid>
          <Grid
            item
            marginY="auto"
            sx={{
              justifyContent: "center",
              display: filterSwitch,
            }}
            xs={12}
            sm={2}
            md={2}
            lg={1}
            xl={1}
          >
            <Button variant="contained" type="submit">
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Paper elevation={4} sx={{ marginTop: 5 }}>
          <DataTable
            expandableRows
            noDataComponent="No hay registros"
            expandableRowsComponent={ExpandedComponent}
            columns={columns}
            data={tabledata}
          />
        </Paper>
        <Grid container pt={5} spacing={4}>
          <Grid item xs={12} sx={{display:"flex", justifyContent:"center"}}>
            <Pagination count={pageAmount + 1} size="large" onChange={handlePaginationChange} variant="outlined" shape="rounded" />
          </Grid>
          <Grid item xs={12} ml={3}>
            <FormControl>
              <InputLabel id="input-client-sex-filter-label" size="small">
                Cant.
              </InputLabel>
              <Select
                defaultValue={limit}
                onChange={(event) => setLimit(event.target.value)}
                size="small"
                id="input-client-sex-filter"
                labelId="input-client-sex-filter-label"
                label="Sexo"
              >
                <MenuItem key={5} value={5}>5</MenuItem>
                <MenuItem key={10} value={10}>10</MenuItem>
                <MenuItem key={20} value={20}>20</MenuItem>
                <MenuItem key={40} value={40}>40</MenuItem>
                <MenuItem key={100} value={100}>100</MenuItem>
                <MenuItem key={99999} value={99999}>Todo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <CRUDControls
        create={true}
        massiveCreate={true}
        onCreate={handleOpenCreate}
      />
    </Box>
  );
}
