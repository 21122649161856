import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages/Home/Home";
import Login from "./pages/Auth/Login";
import { HashRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedRouteLogin from "./components/ProtectedRouteLogin";
import Tenants from "./pages/Tenants/Tenants";
import ACL from "./pages/ACL/ACL";
import Error from "./pages/Error/Error";
import Groups from "./pages/Groups/Groups";
import Visits from "./pages/Visits/Visits";
import Visitors from "./pages/Front/Visitors";
import CajaHistorial from "./pages/CajaHistorial/CajaHistorial";
import Clients from "./pages/Clients/Clients";
import Caja from "./pages/Caja/Caja";
import Deudas from "./pages/Deudas/Deudas";
import Memberships from "./pages/Memberships/Memberships";
import Navbar from "./components/Navbar/Navbar";


import { createTheme, ThemeProvider } from "@mui/material/styles";
import Config from "./pages/Config/Config";
const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const AppLayout = () => 
  <>
    <Navbar />
    <Outlet />
  </>

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route exact path="/" element={<ProtectedRouteLogin><Login /></ProtectedRouteLogin>} />
        {/* <Route exact path="/:tenant/signup" element={<ProtectedRouteLogin><Signup /></ProtectedRouteLogin>} /> */}
        <Route exact path="/:tenant" element={<ProtectedRoute><Visitors /></ProtectedRoute>} />
        <Route element={<AppLayout/>} >
          <Route exact path="/:tenant/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route exact path="/:tenant/tenants" element={<ProtectedRoute><Tenants /></ProtectedRoute>} />
          <Route exact path="/:tenant/ACL" element={<ProtectedRoute><ACL /></ProtectedRoute>} />
          <Route exact path="/:tenant/groups" element={<ProtectedRoute><Groups /></ProtectedRoute>} />
          <Route exact path="/:tenant/clients" element={<ProtectedRoute><Clients /></ProtectedRoute>} />
          <Route exact path="/:tenant/visits" element={<ProtectedRoute><Visits /></ProtectedRoute>} />
          <Route exact path="/:tenant/caja" element={<ProtectedRoute><Caja /></ProtectedRoute>} />
          <Route exact path="/:tenant/caja/historial" element={<ProtectedRoute><CajaHistorial /></ProtectedRoute>} />
          <Route exact path="/:tenant/membership" element={<ProtectedRoute><Memberships /></ProtectedRoute>} />
          <Route exact path="/:tenant/config" element={<ProtectedRoute><Config /></ProtectedRoute>} />
          <Route exact path="/:tenant/deudas" element={<ProtectedRoute><Deudas/></ProtectedRoute>} />
        </Route>
        <Route path="/*" element={<Error />} />
      </Routes>
    </Router>
  </ThemeProvider>
);
