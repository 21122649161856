import React from 'react'
import './Loading.css'

export default function Loading() {

    return (
      <div className="loading">
  
      <h1>Cargando...</h1>
  
      </div>
    )

}
