import React from 'react'
import {useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading/Loading';

const verifyUserToken = (navigate, setLoading) => {
  setLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
        navigate(`/`, { replace: true });
    }
    const Config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
        },
    }
  axios(`${process.env.REACT_APP_API_URL}/auth/verify`,Config).then((res) => {
  }).catch(err => {
    navigate(`/`, { replace: true });
  }).finally(()=> setLoading(false))
};

export default function ProtectedRoute(props) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
  
    useEffect(() => {
      verifyUserToken(navigate, setLoading);
    }, [navigate]);
  
    if (isLoading) {
      return <Loading/>;
    }
    return (
      <>
        {props.children}
      </>
      )
  
}
