import React from "react";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  Button,
  Modal,
  FormGroup,
  Paper,
  FormControlLabel,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from "@mui/material";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DataTable from "react-data-table-component";
import Shortbody from "../../components/Shortbody/Shortbody";
import { useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const fetchDebts = (setDebts) => {
  const Config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  axios(`${process.env.REACT_APP_API_URL}/clients/getdebts`, Config).then(
    (res) => {
      setDebts(res.data);
    }
  );
};

const handlePayAllDebts = (idClient, setDebts, handleCloseModal) => {
  const Config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      idClient,
    },
  };
  console.log(idClient);
  axios(`${process.env.REACT_APP_API_URL}/clients/paydebt`, Config).then(
    (res) => {
      fetchDebts(setDebts)
      handleCloseModal();
      toast.success(`Deuda abonada con exito`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  );
};


const columns = [
  {
    name: "Nombre",
    selector: (row) => row.name,
  },
  {
    name: "DNI",
    selector: (row) => row.dni,
  },
  {
    name: "Deuda",
    selector: (row) => {
      console.log(row)
      let totalDebt = 0;
      row.debts.forEach((debt) => {
        totalDebt += parseInt(debt.amount);
      });
      return '$ '+totalDebt;
    },
  },
  {

  }
];


const fetchDebt = () => {
  const Config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  axios(`${process.env.REACT_APP_API_URL}/clients/getdebt`, Config).then(
    (res) => {
      //Actualizar campos get
    }
  );
};



export default function Deudas() {
  const [debts, setDebts] = useState([]);
  const [modal, setModal] = useState(false);
  const [manage, setManage] = useState({});
  function handleCloseModal() {
    setModal(false);
  }

  useEffect(() => {
    fetchDebts(setDebts);
  }, []);
  
  return (
    <>
    <ToastContainer/>
      <Shortbody>
      <Modal
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper variant="outlined" sx={{ py: 4, px: 4 }}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography marginX="auto" variant="h5">
                Estas seguro de limpiar la deuda del cliente {manage.name} cuyo monto es de {manage.debt}?
              </Typography>
            </Grid>
            <Grid item>
              <FormControl
                variant="standard"
                fullWidth
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Button variant="contained" onClick={handleCloseModal}>Cancelar</Button>
                <Button variant="contained" onClick={()=> handlePayAllDebts(manage.id, setDebts, handleCloseModal)}>Aceptar</Button>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
        <DataTable highlightOnHover pointerOnHover columns={columns} data={debts} onRowClicked={(data) => {
          let debt = 0;
          data.debts.forEach(db => {
            debt += db.amount;
          })
          setManage({id: data._id, name: data.name, debt: debt });

          setModal(true)}} />
      </Shortbody>
    </>
  );
}
