import React from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import Shortbody from "../../components/Shortbody/Shortbody";
import CRUDControls from "../../components/CRUDControls/CRUDControls";
import Modal from "@mui/material/Modal";
import InputAdornment from "@mui/material/InputAdornment";
import RouteIcon from '@mui/icons-material/Route';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import KeyIcon from '@mui/icons-material/Key';
import { Stack } from "@mui/system";
import {
  Button,
  Grid,
  Paper,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";

export default function Tenants() {
  const [data, setData] = useState([{}]);
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);

  const columns = [
    {
      name: "Name",
      selector: (row) => row['name'],
    },
    {
      name: "URL",
      selector: (row) => row['path'],
    },
    {
      name: "Owner",
      selector: (row) => row['owner'],
    },
    {
      name: "Controls",
      selector: "",
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        name: data.get("input-tenant-name"),
        path: data.get("input-tenant-path"),
        owner: {
          email: data.get("input-owner-email"),
          password: data.get("input-owner-password")
        }
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/tenant/maketenant`, Config).then(
      (res) => {
      }
    );
  }

  useEffect(() => {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/tenant/getalltenants`, Config).then(
      (res) => {
        setData(res.data);
      }
    );
  }, []);

  return (
    <>
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        component="form"
        onSubmit={handleSubmit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper  variant="outlined" sx={{py:4, px:4}}>
            <Grid container direction='column' spacing={4}>
              {/* New Tenant title */}
              <Grid item container >
                <Typography marginX='auto' variant="h5">New Tenant</Typography>
              </Grid>
              {/* Tenant name and path */}
              <Grid item container direction='row' spacing={4}>
                <Grid item>
                  <FormControl variant="standard">
                    <InputLabel htmlFor="input-tenant-name">Tenant name</InputLabel>
                    <Input
                      id="input-tenant-name"
                      name="input-tenant-name"
                      startAdornment={
                        <InputAdornment position="start">
                          <AddBusinessIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard">
                    <InputLabel htmlFor="input-tenant-path">Tenant path</InputLabel>
                    <Input
                      id="input-tenant-path"
                      name="input-tenant-path"
                      startAdornment={
                        <InputAdornment position="start">
                          <RouteIcon />
                        </InputAdornment>
                      }
                    />
                    </FormControl>
                </Grid>
              </Grid>
              {/* Owner paper form */}
              <Grid item>
              <Paper sx={{p:4}} elevation={12}>
                <Grid container paddingX='auto' direction='column' spacing={4}>
                  <Grid item container>
                    <Typography>Owner</Typography>
                  </Grid>
                  <Grid item>
                    <FormControl variant="standard">
                    <InputLabel htmlFor="input-owner-email">Email</InputLabel>
                    <Input
                      id="input-owner-email"
                      name="input-owner-email"
                      startAdornment={
                        <InputAdornment position="start">
                          <RouteIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                      <FormControl variant="standard">
                      <InputLabel htmlFor="input-owner-password">Password</InputLabel>
                      <Input
                        id="input-owner-password"
                        name="input-owner-password"
                        startAdornment={
                          <InputAdornment position="start">
                            <KeyIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              </Grid>
            <Grid item>
              {/* Submit and cancel buttons */}
            <Stack spacing={2} direction="row-reverse">
              <Button variant="contained" type="submit" endIcon={<SendIcon />}>
                Submit
              </Button>
              <Button variant="outlined" onClick={handleCloseCreate} startIcon={<DeleteIcon />}>
                Cancel
              </Button>
            </Stack>
            </Grid>
            </Grid>
        </Paper>
      </Modal>
      <Shortbody>
        <Paper>
          <DataTable columns={columns} data={data} />
        </Paper>
      </Shortbody>
      <CRUDControls create={true} onCreate={handleOpenCreate} />
    </>
  );
}
