import React from "react";
import { Grid, Card, Box, Divider, Typography } from "@mui/material";
import {animated, useSpring} from 'react-spring';

function Number({n}) {
    n = parseInt(n);
  const props = useSpring({
    number: n,
    from: { number: 0 },
    config: { mass:1, tension:20, friction: 10 },
  })
  return <animated.span>{props.number.to(n => n.toFixed(0))}</animated.span>
}
export default function GridItemStatCard({ title, value, icon, desc, diff}) {
  return (
    <Grid item xs={12} md={5} lg={3}>
      <Card sx={{ p: 2 }}>
        <Grid
          container
          direction="row"
          sx={{ justifyContent: "space-between" }}
        >
          <Grid item sx={{ alignItems: "flex-end", display: "flex" }}>
            {icon}
          </Grid>
          <Grid item direction="column">
            <Typography textAlign="right" pl={1} style={{ opacity: 0.6 }}>
              {title}
            </Typography>
            <Typography textAlign="right" variant="h4" fontWeight="bold">
              <Number n={value}/>
            </Typography>
          </Grid>
        </Grid>

        <hr />
        <Grid container direction="row">
          <Typography color={"green"} fontWeight="bold">
          <Number n={diff}/>
          </Typography>
          <Typography pl={1} style={{ opacity: 0.6 }}>
            {desc}
          </Typography>
        </Grid>
      </Card>
    </Grid>
  );
}
