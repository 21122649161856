import React, { useEffect, useState } from "react";
import { Grid, Card, Box, Divider, Typography } from "@mui/material";
import GridItemStatCard from "../../components/Home/GridItemStatCard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import axios from 'axios';



export default function Home() {
  const [stats, setStats] = useState();  
  
  useEffect(()=> {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(`${process.env.REACT_APP_API_URL}/stats/getStats`, Config).then((res) => {
      setStats(res.data)
      console.log(res.data);
    })
  },[])
  return (
    <Box>
      <Grid container spacing={4} pl={4} pt={4}>
        <GridItemStatCard title="Clientes activos" value={stats && stats.totalActiveClients} desc="En total." diff={stats && stats.totalClients} icon={<PeopleAltIcon sx={{fontSize:"46px"}}/>} />
        <GridItemStatCard title="Visitas" value={stats && stats.totalVisits} desc="" diff="0" icon={<PeopleAltIcon sx={{fontSize:"46px"}}/>} />
        
      </Grid>
    </Box>
  );
}
