import React from "react";
import DataTable from "react-data-table-component";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import {
  TextField,
  Paper,
  Modal,
  Grid,
  Typography,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Button,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Stack } from "@mui/system";

const columns = [
  {
    name: "Fecha",
    selector: (row) => {
      const dateformat = new Date(row["fecha"]).toLocaleString(process.env.REACT_APP_LOCALE);
      if(dateformat === "Invalid Date") return row["fecha"];
      return dateformat},
    grow: 1,
  },
  {
    name: "Autor",
    grow: 1,
    selector: (row) => row["autor"],
  },
  {
    name: "Concepto",
    wrap: true,
    grow: 2,
    selector: (row) => row["concepto"],
  },
  {
    name: "Egreso",
    grow: 1,
    selector: (row) => parseFloat(row['egreso']).toFixed(2).replace('.',','),
  },
  {
    name: "Ingreso",
    grow: 1,
    selector: (row) => parseFloat(row['ingreso']).toFixed(2).replace('.',','),
  },
  {
    name: "Saldo",
    grow: 1,
    selector: (row) => parseFloat(row['monto']).toFixed(2).replace('.',','),
  },
]



export default function Caja() {
  const navigate = useNavigate();
  const [tabledata, setTableData] = useState([]);


  // Funciones para abrir y cerrar caja
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const [openCreate, setOpenCreate] = useState(false);
  function handleSubmit(event) {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    const Config = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        saldoCaja: data.get('saldo-caja'),
      }
    }
    axios(`${process.env.REACT_APP_API_URL}/caja/addcaja`, Config)
    .then(res => {
      setTableData([...tabledata, res.data])
    }).catch(err => {console.log(err.message)})
    .finally(()=> {
      handleCloseCreate();
      getCajaData();
    })
  }
  function handleCerrarCaja(event) {
    event.preventDefault();
    const Config = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    axios(`${process.env.REACT_APP_API_URL}/caja/cerrarcurrentcaja`, Config)
    .then(res => {
      setTableData()
    }).catch(err => {console.log(err.message)})

  }

  // Funciones para el egreso
  const [openEgreso, setOpenEgreso] = useState(false);
  const handleOpenEgreso = () => setOpenEgreso(true); 
  const handleCloseEgreso = () => setOpenEgreso(false);
  function handleAddEgreso(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Config = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        egreso: data.get('egreso-caja'),
        concepto: data.get('concepto-egreso-caja'),
      }
    }
    axios(`${process.env.REACT_APP_API_URL}/caja/addegreso`, Config)
    .then(res => {
    }).catch(err => {console.log(err.message)})
    .finally(()=> {
      getCajaData();
      handleCloseEgreso();
    })

  }

  // Funciones para el ingreso
  const [openIngreso, setOpenIngreso] = useState(false);
  const handleOpenIngreso = () => setOpenIngreso(true);
  const handleCloseIngreso = () => setOpenIngreso(false);
  function handleAddIngreso(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Config = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        ingreso: data.get('ingreso-caja'),
        concepto: data.get('concepto-caja'),
      }
    }
    axios(`${process.env.REACT_APP_API_URL}/caja/addingreso`, Config)
    .then(res => {
    }).catch(err => {console.log(err.message)})
    .finally(()=> {
      getCajaData();
      handleCloseIngreso();
    })
    
  }

  function getCajaData() {
    const Config = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    axios(`${process.env.REACT_APP_API_URL}/caja/getcurrentcaja`, Config)
    .then(res => {
      setTableData(res.data.movimientos)
    }).catch(err => {console.log(err.message)})

  }
  
  const [ultimoSaldo, setUltimoSaldo] = useState();
  function cargarUltimoSaldo() {
    const Config = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    axios(`${process.env.REACT_APP_API_URL}/caja/getultimosaldocaja`, Config)
    .then(res => {
      setUltimoSaldo(res.data.ultimoSaldo);
    }).catch(err => {console.log(err.message)})
  }

  useEffect(() => {
    getCajaData();
  },[])

  return (
    <>
    <Modal
      open={openCreate}
      onClose={handleCloseCreate}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Paper variant="outlined" sx={{ py: 4, px: 4 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item><Typography marginX='auto' variant="h5">Iniciar Caja</Typography></Grid>
          <Grid item>
            <Button onClick={cargarUltimoSaldo} variant="contained">Cargar ultimo saldo de caja</Button>
          </Grid>
          <Grid item>
              <FormControl variant="standard">
                      <InputLabel htmlFor="input-saldo-caja">Saldo efectivo</InputLabel>
                      <Input
                      required
                      type='float'
                      name="saldo-caja"
                      value={ultimoSaldo}
                      id="input-saldo-caja"
                      startAdornment={
                          <InputAdornment position="start">
                          <AddBusinessIcon />
                          </InputAdornment>
                      }
                      />
              </FormControl>
          </Grid>
          <Grid item>
          <Stack spacing={2} direction="row">
            <Button variant="outlined" onClick={handleCloseCreate} startIcon={<DeleteIcon />}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" endIcon={<SendIcon />}>
              Submit
            </Button>
          </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Modal>

    {/* Ingreso modal */}
    <Modal
    sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    component="form"
    onSubmit={handleAddIngreso}
    onClose={handleCloseIngreso}
    open={openIngreso}>
      <Paper variant="outlined" sx={{ py: 4, px: 4 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item><Typography marginX='auto' variant="h5">Registrar Ingreso</Typography></Grid>
          <Grid item>
            <FormControl variant="standard">
                    <InputLabel htmlFor="input-saldo-caja">Saldo efectivo</InputLabel>
                    <Input
                    required
                    type='float'
                    name="ingreso-caja"
                    id="input-ingreso-caja"
                    startAdornment={

                        <InputAdornment position="start">
                        <LocalAtmIcon />
                        </InputAdornment>
                    }
                    />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="standard">
                    <TextField
                    required
                    multiline
                    minRows={4}
                    type='text'
                    variant='filled'
                    name="concepto-caja"
                    id="input-concepto-caja"
                    label="Concepto"
                    />
            </FormControl>
          </Grid>
          <Grid item>
          <Stack spacing={2} direction="row">
            <Button variant="outlined" onClick={handleCloseIngreso} startIcon={<DeleteIcon />}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit" endIcon={<SendIcon />}>
              Aceptar
            </Button>
          </Stack>
          </Grid>
        </Grid>

      </Paper>
    </Modal>

    {/* Egreso Modal */}

    <Modal
    sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    component="form"
    onSubmit={handleAddEgreso}
    onClose={handleCloseEgreso}
    open={openEgreso}>
      <Paper variant="outlined" sx={{ py: 4, px: 4 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item><Typography marginX='auto' variant="h5">Registrar Egreso</Typography></Grid>
          <Grid item>
            <FormControl variant="standard">
                    <InputLabel htmlFor="input-saldo-caja">Saldo efectivo</InputLabel>
                    <Input
                    required
                    type='float'
                    name="egreso-caja"
                    id="input-egreso-caja"
                    startAdornment={

                        <InputAdornment position="start">
                        <LocalAtmIcon />
                        </InputAdornment>
                    }
                    />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="standard">
                    <TextField
                    required
                    multiline
                    minRows={4}
                    type='text'
                    variant='filled'
                    name="concepto-egreso-caja"
                    id="input-concepto-egreso-caja"
                    label="Concepto"
                    />
            </FormControl>
          </Grid>
          <Grid item>
          <Stack spacing={2} direction="row">
            <Button variant="outlined" onClick={handleCloseEgreso} startIcon={<DeleteIcon />}>
              Cancelar
            </Button>
            <Button variant="contained" type="submit" endIcon={<SendIcon />}>
              Aceptar
            </Button>
          </Stack>
          </Grid>
        </Grid>

      </Paper>
    </Modal>
    <Paper sx={{margin:4, padding:4}} elevation={6}>
        <Box p={3} sx={{display:'flex', flexDirection:'row-reverse'}}>
          <Box maxWidth='150px'>
            <Button sx={{flex:1}} variant='contained' color='info' onClick={()=>navigate('historial')}>Historial</Button>
          </Box>
          <Box maxWidth='150px'>
          {tabledata && tabledata.length > 0?<Button sx={{flex:1}} onClick={handleCerrarCaja} variant='contained' color="error">Cerrar Caja</Button>
          :<Button sx={{flex:1}} onClick={handleOpenCreate} variant='contained' color='success'>Abrir Caja</Button>}
          </Box>
        </Box>
        <Box gap={6} sx={{display:'flex', flexDirection:'row'}}>
        {tabledata && tabledata.length > 0?<><Button sx={{flex:1}} variant='contained' color="success" onClick={handleOpenIngreso}>Registrar Ingreso</Button>
        <Button sx={{flex:1}} variant='contained' color="error" onClick={handleOpenEgreso}>Registrar Egreso</Button></>:null}
        </Box>
        <DataTable highlightOnHover columns={columns} data={tabledata} />
    </Paper>

      


  </>
  )
}
