import React from "react";
import {useState} from 'react';
import Checkbox from "@mui/material/Checkbox";
import {
  FormGroup,
  Paper,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";
import Shortbody from "../../components/Shortbody/Shortbody";
import { useEffect } from "react";
import axios from "axios";

const fetchOptions = (setOptions) => {
  const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
  }
  axios(`${process.env.REACT_APP_API_URL}/tenant/gettenantconfig`,Config).then((res)=> {
      setOptions(res.data)
  })
}

export default function Config() {
    const [options, setOptions] = useState();

    

    useEffect(()=> {
        fetchOptions(setOptions);
    },[])
    function optionChange(e, option) {
        const Config = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: {
              regAbCaja: !option
            },
        }
        axios(`${process.env.REACT_APP_API_URL}/tenant/updatetenantconfig`,Config).then((res)=> {
            fetchOptions(setOptions);
        })
    }

  return (
    <>
      <Shortbody>
        <Grid container direction='column' spacing={3} sx={{justifyContent:'center', alignItems:'center'}}>
          <Grid item>
            <Typography variant="h2">Opciones</Typography>
          </Grid>
          <Grid item>
            <FormGroup>
              <Paper>
                <FormControlLabel
                  control={<Checkbox onClick={(e)=> {
                    optionChange(e, options[0].value === 'true'?true:false);

                }} checked={options && options[0].value === 'true'?true:false} />}
                  label="Registrar abonos en caja"
                />
              </Paper>
            </FormGroup>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
      </Shortbody>
    </>
  );
}
