import React from "react";
import "./Error.css";
export default function Error() {
  return (
    <div className="errorbg">
      <h4 className="errortitle">Error😩</h4>
      <p className="errorp">You shouldn't be here.</p>
    </div>
  );
}
