import React from "react";
import Shortbody from "../../components/Shortbody/Shortbody";
import DataTable from "react-data-table-component";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode"
import {
  Paper,
  Modal,
  Grid,
  Typography,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useState, useEffect } from "react";
import CRUDControls from "../../components/CRUDControls/CRUDControls";
import axios from "axios";
import { Stack } from "@mui/system";

export default function Memberships() {
  const [tabledata, setTableData] = useState([]);
  //Crear membresia
  const [currentMembership, setCurrentMembership] = useState();
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  //Editar membresia
  const [openEditarMembresia, setOpenEditarMembresia] = useState(false);
  const handleOpenEditarMembresia = (membershipId) => {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        membershipId
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/getmembershipbyid`, Config)
    .then((res) => {
      setCurrentMembership(res.data);
      setOpenEditarMembresia(true);
    })
    .catch((err) => {
      console.log(err.message);
    });
    
  }
  const handleCloseEditarMembresia = () => setOpenEditarMembresia(false);

  useEffect(() => {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(
      `${process.env.REACT_APP_API_URL}/clients/getmembershiplist`,
      Config
    ).then((res) => {
      setTableData(res.data);
    });
  }, []);


  const columns = [
    {
      name: "Nombre",
      selector: (row) => row["name"],
    },
    {
      name: "Costo",
      selector: (row) => row["cost"],
    },

    {
      name: "Fecha de creacion",
      selector: (row) => {
        const dateformat = new Date(row["createdAt"]).toLocaleString();
        if (dateformat === "Invalid Date") return row["createdAt"];
        return dateformat;
      },
    },
    {
      name: "Controles",
      selector: (row) => 
        {
          return <Button variant="contained" onClick={()=> handleOpenEditarMembresia(row["_id"])}>Editar</Button>
        }
    },
  ];

  const handleSubmitEditarMembresia = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        membershipId: data.get("ms-id"),
        fields: {
          name: data.get("ms-name"),
          cost: data.get("ms-cost"),
        },
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/updatemembershipbyid`, Config).then(()=> {
      window.location.reload();
    }).catch((err) =>{
      console.log(err);
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        name: data.get("client-name"),
        payday: data.get("client-payday"),
        cost: data.get("client-cost"),
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/clients/makemembership`, Config)
      .then((res) => {
        setTableData([...tabledata, res.data]);
        handleCloseCreate();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      {/*CREAR MEMBRESIA MODAL */}
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Paper variant="outlined" sx={{ py: 4, px: 4 }}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography marginX="auto" variant="h5">
                Nueva membresia
              </Typography>
            </Grid>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="input-tenant-name">Nombre</InputLabel>
                <Input
                  required
                  name="client-name"
                  id="input-client-name"
                  startAdornment={
                    <InputAdornment position="start">
                      <AddBusinessIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard">
                <InputLabel htmlFor="input-tenant-name">Costo</InputLabel>
                <Input
                  type="number"
                  required
                  name="client-cost"
                  id="input-client-cost"
                  startAdornment={
                    <InputAdornment position="start">
                      <AddBusinessIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  onClick={handleCloseCreate}
                  startIcon={<DeleteIcon />}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<SendIcon />}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/*EDITAR MEMBRESIA MODAL */}
      <Modal
        open={openEditarMembresia}
        onClose={handleCloseEditarMembresia}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
        onSubmit={handleSubmitEditarMembresia}
      >
        <Paper variant="outlined" sx={{ py: 4, px: 4, mx: "auto", width: 600 }}>
          <Grid container spacing={4}>
            {/*DATOS VIEJOS*/}
            <input
              type="text"
              readOnly
              name="ms-id"
              style={{ display: "none" }}
              value={currentMembership && currentMembership._id}
            />
            <Grid item container xs={6} spacing={2}>
              <Grid item xs={12}>
                <Typography marginX="auto" variant="h5">
                  Datos Actuales
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel>Nombre</InputLabel>
                  <Input
                    disabled
                    value={currentMembership && currentMembership.name}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountBoxIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel>Costo</InputLabel>
                  <Input
                    disabled
                    value={currentMembership && currentMembership.cost}
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/*DATOS NUEVOS */}
            <Grid item container xs={6} spacing={2}>
              <Grid item xs={12}>
                <Typography marginX="auto" variant="h5">
                  Datos Nuevos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="input-ms-name">Nombre</InputLabel>
                  <Input
                    name="ms-name"
                    id="input-ms-name"
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountBoxIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="input-ms-cost">Costo</InputLabel>
                  <Input
                    id="input-ms-cost"
                    name="ms-cost"
                    startAdornment={
                      <InputAdornment position="start">
                        <ChromeReaderModeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SendIcon />}
                >
                  Aceptar
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCloseEditarMembresia}
                  startIcon={<DeleteIcon />}
                >
                  Cancelar
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>

      </Modal>
      <Shortbody>
        <Paper>
          <h1 style={{ textAlign: "center" }}>Membresias</h1>
          <DataTable selectableRows columns={columns} data={tabledata} />
        </Paper>
      </Shortbody>
      <CRUDControls create={true} onCreate={handleOpenCreate} />
    </>
  );
}
