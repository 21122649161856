import React from "react";
import Shortbody from "../../components/Shortbody/Shortbody";
import DataTable from "react-data-table-component";
import DeleteIcon from '@mui/icons-material/Delete';
import Textarea from '@mui/joy/Textarea';
import {
  Paper,
  Modal,
  Grid,
  Typography,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Button,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useState, useEffect } from "react";
import CRUDControls from "../../components/CRUDControls/CRUDControls";
import axios from "axios";
import { Stack } from "@mui/system";
const columns = [
  {
    name: "Name",
    selector: (row) => row["name"],
  },
  {
    name: "Description",
    selector: (row) => row["description"],
  },
  {
    name: "Created At",
    selector: (row) => {
      const dateformat = new Date(row["createdAt"]).toLocaleString();
      if(dateformat === "Invalid Date") return row["createdAt"];
      return dateformat;
    },
  },
  {
    name: "Controls",
    selector: "",
  },
];

export default function Groups() {
  const [tabledata, setTableData] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);


  const handleSubmit = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    const Config = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        name: data.get('name'),
        description: data.get('description'),
      }
    }
    axios(`${process.env.REACT_APP_API_URL}/groups/makegroup`, Config)
    .then(res => {
      setTableData([...tabledata, res.data])
    }).catch(err => {console.log(err.message)})
  };

  useEffect(() => {
    const Config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios(`${process.env.REACT_APP_API_URL}/groups/getallgroups`, Config).then(
      (res) => {
        setTableData(res.data);
      }
    );
  }, []);
  return (
    <>
      <Modal
        open={openCreate}
        onClose={handleCloseCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Paper variant="outlined" sx={{ py: 4, px: 4 }}>
          <Grid container direction="column" spacing={4}>
            <Grid item><Typography marginX='auto' variant="h5">Add Group</Typography></Grid>
            <Grid item>
                <FormControl variant="standard">
                        <InputLabel htmlFor="input-tenant-name">Group name</InputLabel>
                        <Input
                        required
                        name="name"
                        id="input-tenant-name"
                        startAdornment={
                            <InputAdornment position="start">
                            <AddBusinessIcon />
                            </InputAdornment>
                        }
                        />
                </FormControl>
            </Grid>
            <Grid item>
                <InputLabel shrink={true} htmlFor="input-tenant-name">Description</InputLabel>
                <FormControl sx={{ width: 500, maxWidth: '100%' }} variant="standard">
                  <Textarea id="input-tenant-description" name="description" minRows={5}/>
                </FormControl>
            </Grid>
            <Grid item>
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={handleCloseCreate} startIcon={<DeleteIcon />}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" endIcon={<SendIcon />}>
                Submit
              </Button>
            </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <Shortbody>
        <Paper>
          <DataTable selectableRows columns={columns} data={tabledata} />
        </Paper>
      </Shortbody>
      <CRUDControls create={true} onCreate={handleOpenCreate} />
    </>
  );
}
